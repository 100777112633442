.display-hats {
    text-align: left;
    font-size: 80px;
    font-weight: 100;
    color: #EB4D4D;
    margin-bottom: 130px;
}

.display-hats button {
    margin-bottom: 0px;
    width: 65px;
    margin-right: 5px;
    float: left;
}

.display-hats img {
    position: relative;
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.hats-header {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 100;
    margin-bottom: 10px;
    position: relative;
}

.buttons-style {
    position: absolute;
    width: 200px;
    left: 80%;
    transform: translateX(125%);
    color: #954a4a;
    border: 2px #ff0000 solid;
    font-family: Lato;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 300;
    transition: background-color 0.3s, color 0.3s;
}

.buttons-style:hover {
    font-weight: 600;
}

.buttons-style:hover i {
    margin-right: 20px;
}

.buttons-style:focus {
    outline: none;
}

.btn-upload,
.btn-login {
    top: 520px;
}


.push-down input {
    margin-top: 65px;
}

.push-down button {
    margin-bottom: 25px;
}



