body {
    background-image: linear-gradient(rgba(62, 20, 20, 0.4), rgba(62, 20, 20, 0.4)), url(back.jpg);
    background-size: cover;
    background-position: center;
    font-family: Lato;
    font-weight: 300;
    position: relative;
    height: 100vh;
    color: #555;
}


.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.wrapper {
    width: 1000px;
    position: absolute;
    margin: 0 auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1001px) {
    .wrapper {
        height: 600px;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}


@media (max-width: 1000px) {
    .wrapper {
        width: 100%;

        grid-template-columns: 1fr;
        grid-gap: 10px;
        grid-template-rows: 600px 500px 100px;
    }
}


.avatarEditor {
    background-color: #fff;
    grid-column: 1 / 3;
    grid-row: 1/5;
    border: 10px rgba(201, 24, 24, 0.78) solid;
}

@media (max-width: 1000px) {
    .avatarEditor {
        grid-column: 1/2;
        grid-row: 1/2;
    }
}

.hatSelect {
    background-color: #fff;
    grid-column: 3/3;
    grid-row: 1/4;
    border: 10px rgba(201, 24, 24, 0.78) solid;
}

@media (max-width: 1000px) {
    .hatSelect {
        grid-column: 1/2;
        grid-row: 2/3;
    }
}

.avatarSelect {
    background-color: #fff;
    float: right;
    grid-column: 3;
    grid-row: 4 / 5;
    border: 10px rgba(201, 24, 24, 0.78) solid;
}

@media (max-width: 1000px) {
    .avatarSelect {
        grid-column: 1/2;
        grid-row: 3/4;
    }
}

.display-hats {
    text-align: left;
    font-size: 80px;
    font-weight: 100;
    color: #EB4D4D;
    margin-bottom: 130px;
}

.display-hats button {
    margin-bottom: 0;
    width: 50px;
    margin-right: 5px;
    float: left;
}

.display-hats .img {
    position: relative;
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.push-down input {
    margin-bottom: 25px;
}

.push-down button {
    margin-bottom: 25px;
}



