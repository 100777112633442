.display-image {
    text-align: center;
    font-size: 80px;
    font-weight: 100;
    color: #EB4D4D;
    margin-bottom: 130px;
    
}


.image-header {
    font-size: 30px;
    text-align: center;
    margin: 0 auto 10px auto;

    width: 256px;
   
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 100;
    vertical-align: middle;
    
    position: relative;
}

.relocate-image{
    margin-top: 50px;
    transform: translate(75%);
}
.save-button {
    margin-top: 75px;
}